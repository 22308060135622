import Dom from "../utils/dom";
import anime from "animejs";
import ImagePreviewNav from "../partials/image-preview-nav";

class Nav {
  constructor(selector) {
    const el = document.querySelector(selector);
    this.dom = new Dom(el, [
      "bg",
      "divider",
      "panelBg",
      "primaryList",
      "primaryLink",
      "secondaryLink",
      "container",
      "viewMore",
    ]);
    this.dom.add("btnMenu", document);
    this.isOpen = false;
    this.isAnimating = false;

    this.imagePreviewNav = new ImagePreviewNav(".js-imagePreviewNav");
  }

  init() {
    // menu toggle
    if (this.dom.exists("btnMenu")) {
      this.dom.btnMenu[0].onclick = (event) => {
        event.preventDefault();
        this.toggle();
      };
    }

    if (this.dom.exists("primaryLink")) {
      this.dom.primaryLink.forEach((el, index) => {
        el.onmouseover = (event) => {
          this.dom.primaryList[0].setAttribute("data-nav-hover", "true");
        };
        el.onmouseout = (event) => {
          this.dom.primaryList[0].removeAttribute("data-nav-hover");
        };
      });
    }

    this.dom.bg[0].onclick = (event) => {
      event.preventDefault();
      this.close();
    };
  }

  toggle() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    if (!this.isAnimating) {
      this.setState("opening");
      this.isAnimating = true;

      anime({
        targets: this.dom.panelBg[0],
        translateY: ["-100%", "0%"],
        easing: "cubicBezier(.165,.84,.44,1)",
        duration: 700,
        complete: () => {
          this.isOpen = true;
          this.setState("open");
          this.isAnimating = false;
        },
      });

      anime({
        targets: this.dom.bg[0],
        opacity: 1,
        duration: 600,
        easing: "easeOutSine",
      });

      anime({
        targets: this.dom.divider[0],
        opacity: [0, 1],
        duration: 500,
        easing: "easeOutSine",
        delay: 100,
      });

      anime({
        targets: this.dom.primaryLink,
        opacity: [0, 1],
        translateX: ["-50", "0"],
        delay: anime.stagger(40, { start: 0 }),
        duration: 450,
        easing: "easeOutSine",
      });

      const timeline = anime.timeline();
      timeline.add({
        targets: this.dom.secondaryLink,
        opacity: [0, 1],
        translateX: ["-50", "0"],
        delay: anime.stagger(40, { start: 0 }),
        duration: 450,
        easing: "easeOutSine",
      });
      timeline.add({
        targets: this.dom.viewMore[0],
        opacity: [0, 1],
        duration: 450,
        easing: "easeOutSine",
      });
    }
  }

  close() {
    if (!this.isAnimating) {
      this.setState("closing");
      this.isAnimating = true;

      anime({
        targets: this.dom.bg[0],
        opacity: 0,
        duration: 500,
        easing: "easeOutCirc",
      });

      anime({
        targets: [this.dom.container[0], this.dom.divider[0]],
        opacity: 0,
        duration: 500,
        easing: "easeOutCirc",
      });

      anime({
        targets: this.dom.panelBg[0],
        translateY: ["0%", "-100%"],
        easing: "cubicBezier(.165,.84,.44,1)",
        duration: 450,
        delay: 250,
        complete: () => {
          this.isOpen = false;
          this.setState("closed");
          this.isAnimating = false;
          anime.set(this.dom.panelBg[0], { translateY: 0 });
          anime.set(this.dom.container[0], { opacity: 1 });
        },
      });
    }
  }

  setState(state) {
    document.documentElement.setAttribute("data-nav-state", state);
  }
}

export default Nav;
