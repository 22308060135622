import Dom from '../utils/dom'
import Flickity from 'flickity-fade'

class Carousel {
  constructor (el, options = {}) {
    this.dom = new Dom(el, [
      'slide',
      'slideCounter'
    ])

    if (this.dom.slide.length > 1) {
      this.flickity = new Flickity(this.dom.root, {
        cellSelector: '.js-slide',
        prevNextButtons: false,
        cellAlign: 'left',
        pageDots: false,
        wrapAround: true,
        percentPosition: false,
        autoPlay: 3000,
        setGallerySize: (typeof options.setGallerySize !== 'undefined') ? options.setGallerySize : true,
        draggable: (typeof options.draggable !== 'undefined') ? options.draggable : true,
        selectedAttraction: 0.1,
        fade: true
      })
      this.flickity.on('change', (index) => {
        this.updateCounter()
      })
      this.updateCounter()

      this.clickNextEl = this.dom.root.querySelectorAll(options.clickNextSelector)
      if (options.clickNextSelector && this.clickNextEl.length) {
        this.clickNextEl.forEach((el, index) => {
          el.onclick = (event) => {
            event.preventDefault()
            this.flickity.next()
          }
        })
      }

      this.clickPrevEl = this.dom.root.querySelectorAll(options.clickPrevSelector)
      if (options.clickPrevSelector && this.clickPrevEl.length) {
        this.clickPrevEl.forEach((el, index) => {
          el.onclick = (event) => {
            event.preventDefault()
            this.flickity.previous()
          }
        })
      }
    }
  }

  updateCounter () {
    if (this.dom.exists('slideCounter') && this.flickity) {
      this.dom.slideCounter[0].innerHTML = this.flickity.selectedElement.dataset.counter
    }
  }
}

export default Carousel
