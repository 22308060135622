import Dom from '../utils/dom'
import VideoLightbox from '../partials/video-lightbox'

class Artwork {
  constructor (el) {
    this.dom = new Dom(el, [
      'videoLightbox',
      'videoTrigger'
    ])

    if (this.dom.exists('videoLightbox')) {
      this.dom.videoLightbox.forEach((el, index) => {
        const videoLightbox = new VideoLightbox(el)
        this.dom.videoTrigger[index].onclick = () => {
          videoLightbox.open()
        }
      })
    }
  }
}

export default Artwork
