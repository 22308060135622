import Dom from '../utils/dom'

class ImagePreviewNav {
  constructor (selector) {
    const el = document.querySelector(selector)

    this.dom = new Dom(el, [
      'previewImageLink'
    ])

    this.dom.previewImageLink.forEach((el, index) => {
      const imageID = el.dataset.id
      const targetImage = this.dom.root.querySelector('.js-previewImage[data-id="' + imageID + '"]')

      if (targetImage) {
        el.onmouseover = (event) => {
          targetImage.setAttribute('data-active', 'true')
          this.dom.root.setAttribute('data-nav-hover', 'true')
        }
        el.onmouseout = (event) => {
          targetImage.setAttribute('data-active', 'false')
          this.dom.root.removeAttribute('data-nav-hover')
        }
      }
    })
  }
}

export default ImagePreviewNav
