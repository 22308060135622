import Dom from '../utils/dom'
import YouTubePlayer from '../partials/youtube-player'
import VimeoPlayer from '../partials/vimeo-player'

class VideoLightbox {
  constructor (el) {
    this.dom = new Dom(el, [
      'embed',
      'close'
    ])

    this.playerType = this.dom.root.dataset.type
    this.videoID = this.dom.embed[0].id.substr(6)
    this.autoplay = this.dom.root.dataset.autoplay
    this.controls = this.dom.root.dataset.controls

    // move to root dom to prevent any z-index issues
    document.body.appendChild(this.dom.root)

    if (this.playerType === 'youtube') {
      this.player = new YouTubePlayer({
        id: this.videoID,
        autoplay: this.autoplay,
        controls: this.controls
      })
    } else if (this.playerType === 'vimeo') {
      this.player = new VimeoPlayer({
        id: this.videoID,
        autoplay: this.autoplay,
        controls: this.controls
      })
    } else {
      console.warn('video.js - no player type defined')
      return
    }

    this.player.emitter.on('ready', (event) => { this.setState('ready') })
    this.player.emitter.on('playing', (event) => { this.setState('playing') })
    this.player.emitter.on('loading', (event) => { this.setState('loading') })
    this.player.emitter.on('paused', (event) => { this.setState('paused') })
    this.player.emitter.on('ended', (event) => { this.setState('ended') })

    if (this.dom.exists('close')) {
      this.dom.close.forEach((el, index) => {
        el.onclick = () => {
          this.close()
        }
      })
    }

    this.setState('initialising')
  }

  open () {
    this.dom.root.setAttribute('data-open', 'true')
    this.player.play()
  }

  close () {
    this.dom.root.setAttribute('data-open', 'false')
    this.player.stop()
  }

  setState (state) {
    this.dom.root.setAttribute('data-state', state)
  }
}

export default VideoLightbox
