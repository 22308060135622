import Dom from '../utils/dom'
import Carousel from '../partials/carousel'

class Home {
  constructor (el) {
    this.dom = new Dom(el, [
      'carousel',
      'slide'
    ])

    if (this.dom.exists('carousel') && this.dom.exists('slide')) {
      if (this.dom.slide.length > 1) {
        this.carousel = new Carousel(this.dom.carousel[0], {
          setGallerySize: false,
          draggable: false,
          clickNextSelector: '.js-clickToAdvance'
        })
      }
    }
  }
}

export default Home
