import Dom from "../utils/dom";

class Header {
  constructor(selector) {
    const el = document.querySelector(selector);
    this.dom = new Dom(el, []);
  }

  init() {}
}

export default Header;
